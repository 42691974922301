<template>
  <div id='update_user_info'>
    <van-form ref="form" @submit="onSubmit">
      <div class="header">
        <van-uploader :after-read="afterRead" :max-count="1">
          <img v-if="params.avatar ":src="params.avatar" />
          <img v-else :src="require('@img/home/user_avatar.png')" />
          <div class="header_overlay"><van-icon name="photograph" /></div>
        </van-uploader>
      </div>
      <!-- 用户名    用户名 -->
      <van-field v-model="params.username" name="username" :label="language.l_294" :placeholder="language.l_294" autocomplete="off" :rules="rules.username"/>
      <!-- 身份证号    身份证号 -->
      <van-field v-model="params.identity_card" name="identity_card" :label="language.l_127" :placeholder="language.l_127" autocomplete="off"/>
      <!-- 昵称    昵称 -->
      <van-field v-model="params.nickname" name="nickname" :label="language.l_295" :placeholder="language.l_295" autocomplete="off"/>
      <!-- 描述    描述 -->
      <van-field v-model="params.desc" name="desc" :label="language.l_296" :placeholder="language.l_296" autocomplete="off"/>
      <!-- 原密码    原密码 -->
      <van-field v-model="params.source_pwd" name="source_pwd" type="password" :label="language.l_297" :placeholder="language.l_297" autocomplete="off"/>
      <!-- 新密码    新密码 -->
      <van-field v-model="params.new_pwd" name="new_pwd" :label="language.l_298" type="password" :placeholder="language.l_298" autocomplete="off" :rules="rules.new_pwd"/>
      <div class="btns">
        <!-- 提交 -->
        <van-button round block native-type="submit">{{language.l_299}}</van-button>
        <!-- 取消 -->
        <van-button @click.stop="cancel" round block class="buttom_color">{{language.l_139}}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {editUser, uploadImage} from '@api';

  export default {
    data() {
      return {
        params: {
          id: '',
          identity_card: '',
          username: '',
          avatar: '',
          nickname: '',
          email: '',
          desc: '',
          source_pwd: '',
          new_pwd: ''
        },
        language: this.$store.getters.language
      }
    },
    created() {
      if(this.$store.state.userInfo){
        this.params = this.$store.state.userInfo;
      }else{
        this.$router.push({name: 'Login'})
      }
    },
    methods:{
      afterRead(file) {
        file.status = 'uploading';
        file.message = this.language.l_259;  //上传中
        let formData = new FormData();
        formData.append('file', file.file);
        uploadImage(formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
          file.status = 'done';
          file.message = this.language.l_188;  //上传成功
          file.content = res.data.url;
          this.params.avatar = res.data.url
        }).catch(() => {
          file.status = 'failed';
          file.message = this.language.l_260;  //上传失败
        })
      },
      onSubmit() {
        const {params} = this
        editUser(params).then(() => {
          this.$toast.success(this.language.l_300)  //更改成功
          this.$router.replace({name: 'UserInfo'});//返回上一层
        })
      },
      cancel() {
        this.$toast.fail(this.language.l_301)  //取消修改
        this.$router.push({name: 'UserInfo'})
      }
    },
    computed: {
      rules() {
        return {
          username: [{validator: value => Boolean(value), message: this.language.l_302 }],  //用户名不能为空
          source_pwd: [{message: this.language.l_303 }],  //原密码不能为空
          new_pwd: [{validator: value => {
            if(value && !this.params.source_pwd) return false
            return true
          },
          message: this.language.l_303 }],  //原密码不能为空
        }
      }
    },
    mounted() {}
  }
</script>

<style lang='less' scoped>
#update_user_info {
  height: 100vh;
  background-color: #fff;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    .van-uploader {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      /deep/  .van-uploader__wrapper {
        width: 100%;
        height: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .header_overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #ccc;
        font-size: 28px;
        background-color: rgba(0, 0, 0, .6);
      }
    }
  }
  .btns {
    margin: 16px;
    .van-button--default {
      height: 45px;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      border-radius: 5px;
      background-image: @grad_right;
    }
    .buttom_color {
      margin-top: 8px;
      background: @c_light;
    }
  }
}
</style>